/*!
 * OfficeGest
 * ©2021 Guisoft
 *
 * ATENÇÃO! Este ficheiro é o resultado de uma compilação, não deve ser editado diretamente.
 */
 @import "dataTables/dataTables";

 @import "smartWizard/smart_wizard.min";
 @import "smartWizard/smart_wizard_theme_circles.min";

 @import "chosen";
 @import "dropzone";
 @import "jqtree";
//  @import "select2";
 @import "ekko-lightbox";